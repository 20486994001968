<script>
import VideoNewView from './VideoNewView'

export default {
  name: 'VideoEditView',
  extends: VideoNewView,
  data () {
    return {
      edit: true,
      tabs: [
        { name: this.$t('video.tabs.main'), error: false },
        { name: this.$t('video.tabs.video'), error: false },
        { name: this.$t('video.tabs.annotations'), error: false },
        { name: this.$t('video.tabs.stats'), error: false }
      ]
    }
  },
  created () {
    this.getVideo()
  }
}
</script>
