<template>
  <VideoEditView disabled :is-modal="isModal" :video-id="videoId" />
</template>
<script>
import VideoEditView from '@/views/video/VideoEditView'

export default {
  name: 'VideoView',
  components: {
    VideoEditView
  },
  props: {
    videoId: {
      type: Number,
      required: false
    },
    isModal: {
      type: Boolean,
      default: false
    }
  }
}
</script>
